import { Injectable } from '@angular/core';
import { Router, NavigationEnd, Event as NavigationEvent } from '@angular/router';
import { filter } from 'rxjs/operators';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private router: Router) {
    this.router.events.pipe(
      filter((event: NavigationEvent): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      gtag('config', 'G-XJD0M5G2SY', { 'page_path': event.urlAfterRedirects });
    });
  }

  // You can also add other methods to track events, e-commerce, etc.
  public event(eventName: string, params: {}) {
    gtag('event', eventName, params);
  }
}