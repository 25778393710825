import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { map } from 'rxjs/operators';

interface UserDetails {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  state: string;
  phoneNumber: string;
  country: string;
}

interface RegistrationResponse {
  success: boolean;
  user_id: number;
  token?: string;  // Optional token
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private apiUrl = 'https://molhy.com/users/wp-json/custom/v1/login/';
  private registerUrl = 'https://molhy.com/users/wp-json/custom/v1/register/';
  private checkMyKadUrl = 'https://molhy.com/users/wp-json/custom/v1/check-mykad/';
  private authState = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private storage: Storage) {
    this.storage.create().then(() => {
    }).catch(error => {
    });
  }
  
  // Method to check if a MyKad number already exists
  checkMyKadExists(mykadNumber: string): Observable<boolean> {
    return this.http.get<{ exists: boolean }>(`${this.checkMyKadUrl}?mykad=${mykadNumber}`).pipe(
      map(response => response.exists)
    );
  }

  // Method to register a new user
registerUser(
  username: string,
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  mykad: string,
  phoneNumber: string,
  country: string,
  state: string,
  metaData: any[]
): Observable<RegistrationResponse> {
  const body = {
    username,
    email,
    password,
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber,    // Add phone number
    country: country,             // Add country
    state: state,                 // Add state
    meta_data: [
      { key: 'mykad', value: mykad },
      ...metaData
    ]
  };
  return this.http.post<RegistrationResponse>(this.registerUrl, body);
}


  // Method to log in a user using MyKad number
  loginUser(mykad: string): Observable<any> {
    const body = { mykad };
    return this.http.post(this.apiUrl, body);
  }

  // Method to get user details by MyKad (username)
  getUserByMyKad(mykadNumber: string): Observable<any> {
    return this.loginUser(mykadNumber).pipe(
      map(response => {
        if (response && response.token) {
          return {
            id: response.data.user_id,  // Assuming user_id is returned in the data object
            firstName: response.first_name,
            lastName: response.last_name,
            email: response.user_email,
            state: response.state,
            phoneNumber: response.phone_number,
            country: response.country,
          };
        }
        return null;
      })
    );
  }

  // Save JWT token
  async saveToken(token: string): Promise<void> {
    try {
      await this.storage.set('auth_token', token);
    } catch (error) {
      throw error;
    }
  }

  // Retrieve JWT token
  async getToken(): Promise<string | null> {
    try {
      const token = await this.storage.get('auth_token');
      return token;
    } catch (error) {
      return null;
    }
  }

  // Clear JWT token
  async clearToken(): Promise<void> {
    try {
      await this.storage.remove('auth_token');
    } catch (error) {
      throw error;
    }
  }

  async saveUserDetails(id: number, firstName: string, lastName: string, email: string, state: string, phoneNumber: string, country: string): Promise<void> {
    try {
      await this.storage.set('userId', id);
      await this.storage.set('firstName', firstName);
      await this.storage.set('lastName', lastName);
      await this.storage.set('email', email);
      await this.storage.set('state', state);
      await this.storage.set('phoneNumber', phoneNumber);
      await this.storage.set('country', country);
    } catch (error) {
    }
  }

  async getUserDetails(): Promise<UserDetails | null> {
    const id = await this.storage.get('userId');
    const firstName = await this.storage.get('firstName');
    const lastName = await this.storage.get('lastName');
    const email = await this.storage.get('email');
    const state = await this.storage.get('state');
    const phoneNumber = await this.storage.get('phoneNumber');
    const country = await this.storage.get('country');
  
    if (id && firstName && lastName && email && state && phoneNumber && country) {
      return { id, firstName, lastName, email, state, phoneNumber, country };
    }
    return null;
  }

  

  // Sign out the user
async signOut(): Promise<void> {
  try {
    // Clear game-specific data
    await this.storage.remove('submittedLevels'); // Remove saved progress levels
    await this.storage.remove('mykadNumber'); // Clear MyKad number or other specific user data

    // Clear user-specific data
    await this.storage.remove('auth_token');
    await this.storage.remove('firstName');
    await this.storage.remove('lastName');
    await this.storage.remove('email');
    await this.storage.remove('state');
    await this.storage.remove('phoneNumber');
    await this.storage.remove('country');

    // Update authentication state
    this.setAuthState(false);
  } catch (error) {
    console.error('Error during sign out:', error);
  }
}

  
  // Manage authentication state
  setAuthState(isAuthenticated: boolean): void {
    this.authState.next(isAuthenticated);
  }

  getAuthState() {
    return this.authState.asObservable();
  }
}
