import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';
import { MenuController } from '@ionic/angular';
import { LoginService } from './services/login.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isLoggedIn: boolean = false;

  constructor(
    private storage: Storage,
    private translate: TranslateService,
    private loginService: LoginService,
    private menu: MenuController
  ) {
    this.translate.setDefaultLang('en');
  }

 

  switchLanguage(lang: string) {
    this.translate.use(lang); 
    this.storage.set('selectedLanguage', lang); // Save the choice
 }

 async ngOnInit() {
  await this.storage.create();
  const selectedLanguage = await this.storage.get('selectedLanguage') || 'en';
  this.translate.use(selectedLanguage);
} 

openMenu() {
  this.menu.open();
}

async signOut() {
  await this.loginService.signOut();
  this.isLoggedIn = false;
  this.loginService.setAuthState(false);
}

}