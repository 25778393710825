import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'games',
    loadChildren: () => import('./games/games.module').then(m => m.GamesPageModule)
  },
  {
    path: 'contests',
    loadChildren: () => import('./contests/contests.module').then(m => m.ContestsPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountPageModule)
  },
  {
    path: 'policies',
    loadChildren: () => import('./policies/policies.module').then(m => m.PoliciesPageModule)
  },
  // Add your other routes here...
  {
    path: 'searchword',
    loadChildren: () => import('./searchword/searchword.module').then(m => m.searchwordPageModule)
  },
  {
    path: 'phraseword',
    loadChildren: () => import('./phraseword/phraseword.module').then( m => m.phrasewordPageModule)
  },
  {
    path: 'crossword',
    loadChildren: () => import('./crossword/crossword.module').then(m => m.CrosswordPageModule)
  },
  {
    path: 'createword',
    loadChildren: () => import('./createword/createword.module').then( m => m.CreatewordPageModule)
  },
  {
    path: 'reformword',
    loadChildren: () => import('./reformword/reformword.module').then( m => m.reformwordPageModule)
  },
  {
    path: 'guessword',
    loadChildren: () => import('./guessword/guessword.module').then( m => m.GuesswordPageModule)
  },
  {
    path: 'stackword',
    loadChildren: () => import('./stackword/stackword.module').then( m => m.StackwordPageModule)
  },
  {
    path: 'tileword',
    loadChildren: () => import('./tileword/tileword.module').then( m => m.TilewordPageModule)
  },
  {
    path: 'jumbleword',
    loadChildren: () => import('./jumbleword/jumbleword.module').then( m => m.jumblewordPageModule)
  },
  {
    path: 'typeword',
    loadChildren: () => import('./typeword/typeword.module').then( m => m.typewordPageModule)
  },
  {
    path: 'blankword',
    loadChildren: () => import('./blankword/blankword.module').then( m => m.BlankwordPageModule)
  },
  {
    path: 'errorword',
    loadChildren: () => import('./errorword/errorword.module').then( m => m.ErrorwordPageModule)
  },
  {
    path: 'changeword',
    loadChildren: () => import('./changeword/changeword.module').then( m => m.ChangewordPageModule)
  },
  {
    path: 'groupword',
    loadChildren: () => import('./groupword/groupword.module').then( m => m.GroupwordPageModule)
  },
  {
    path:'pictureword',
    loadChildren:()=>import('./pictureword/pictureword.module').then(m=>m.picturewordPageModule)
  },
  {
    path:'audioword',
    loadChildren:()=>import('./audioword/audioword.module').then(m=>m.AudioWordPageModule)
  },
  {
    path: 'choiceword',
    loadChildren: () => import('./choiceword/choiceword.module').then( m => m.ChoicewordPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  { path: 'contest/:id', loadChildren: () => import('./contest-details/contest-details.module').then(m => m.ContestDetailsPageModule) },
  {
    path: 'matchword',
    loadChildren: () => import('./matchword/matchword.module').then(m => m.MatchWordPageModule)
  },
  {
    path: 'wordhunt',
    loadChildren: () => import('./wordhunt/wordhunt.module').then( m => m.WordhuntPageModule)
  },
//   {
//    path: 'certificate',
//    loadChildren: () => import('./certificate/certificate.module').then( m => m.CertificatePageModule)
//  },

  {
    path: 'quiz',
    loadChildren: () => import('./quiz/quiz.module').then( m => m.QuizPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'leaderboard',
    loadChildren: () => import('./leaderboard/leaderboard.module').then( m => m.LeaderboardPageModule)
  },
  {
    path: 'tamilict',
    loadChildren: () => import('./tamilict/tamilict.module').then( m => m.TamilictPageModule)
  },
  {
    path: 'matchquiz',
    loadChildren: () => import('./matchquiz/matchquiz.module').then( m => m.MatchquizPageModule)
  },
  {
    path: 'mwhwinners',
    loadChildren: () => import('./mwhwinners/mwhwinners.module').then( m => m.MwhwinnersPageModule)
  },
  {
    path: 'mwhecert',
    loadChildren: () => import('./mwhecert/mwhecert.module').then( m => m.MwhecertPageModule)
  },
  {
    path: 'mwh2024',
    loadChildren: () => import('./mwh2024/mwh2024.module').then( m => m.Mwh2024PageModule)
  },
  {
    path: 'scoreboard',
    loadChildren: () => import('./scoreboard/scoreboard.module').then( m => m.ScoreboardPageModule)
  },
  {
    path: 'tquizrank',
    loadChildren: () => import('./tquizrank/tquizrank.module').then( m => m.TquizrankPageModule)
  },
  {
    path: 'question',
    loadChildren: () => import('./question/question.module').then( m => m.QuestionPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
