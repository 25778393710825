<ion-app>
  <!-- Side Menu -->
  <ion-menu contentId="main-content" side="start">
    <ion-content class="menu-content">
      <div class="logo-container">
        <a href="/">
          <img src="assets/images/molhy-light.png" alt="Molhy Logo" class="menu-logo">
        </a>
      </div>
      <ion-list lines="none">
        <ion-menu-toggle auto-hide="true">
          <ion-item routerLink="/" routerLinkActive="active">
            <ion-icon slot="start" name="home-outline"></ion-icon>
            <ion-label>Home</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle auto-hide="true">
          <ion-item routerLink="/games" routerLinkActive="active">
            <ion-icon slot="start" name="game-controller-outline"></ion-icon>
            <ion-label>Games</ion-label>
          </ion-item>
        </ion-menu-toggle>
         <ion-menu-toggle auto-hide="true">
          <ion-item routerLink="/contests" routerLinkActive="active">
            <ion-icon slot="start" name="trophy-outline"></ion-icon>
            <ion-label>Contests</ion-label>
          </ion-item>
        </ion-menu-toggle> 
        <ion-menu-toggle auto-hide="true">
          <ion-item routerLink="/leaderboard" routerLinkActive="active">
            <ion-icon slot="start" name="podium-outline"></ion-icon>
            <ion-label>Leaderboard</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>

  <!-- Main content -->
  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-app>
